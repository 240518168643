import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import { profession } from '../Constants';
import Logo from '../logo';

const styles = ({
  breakpoints,
  palette,
  transitions: { create, duration, easing }
}) => ({
  container: {
    textAlign: 'center',
    color: palette.text.secondary
  },
  //   h1: {
  //     fontSize: '3rem',
  //     fontWeight: 'semibold',
  //     color: 'inherit',
  //   },
  h2: {
    fontSize: '2rem',
    color: 'inherit',
    opacity: 0,
    transition: create('opacity', duration.enteringScreen, easing.easeIn),
    [breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    }
  },
  logo: {
    height: '10rem',
    [breakpoints.down('sm')]: {
      height: '6.5rem'
    }
  }
});

class Intro extends React.PureComponent {

  render() {
    const { props } = this;
    return (
      <div className={props.classes.container}>
        <Logo 
        className={props.classes.logo}/>
        <Typography
          id={'introSubtitle'}
          variant={'h2'}
          className={props.classes.h2}>
          {profession}
        </Typography>
      </div>
    );
  }
}
export default withStyles(styles)(Intro);
