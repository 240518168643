import 'babel-polyfill';
import 'intersection-observer';
import './RequestAnimationFramePolyfill';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

