import adobeAI from "./adobeAI.svg";
import adobeXD from "./adobeXD.svg";
import css3 from "./css3.svg";
import firebase from "./firebase.svg";
import flow from "./flow.svg";
import flStudio from './flStudio.svg';
import github from "./github.svg";
import googleCloud from "./googleCloud.svg";
import html5 from "./html5.svg";
import java from "./java.svg";
import javascript from "./javascript.svg";
import jest from "./jest.svg";
import lighthouse from "./lighthouse.svg";
import mailchimp from "./mailchimp.svg";
import mandrill from "./mandrill.svg";
import materialUI from "./materialUI.svg";
import nodeJS from "./nodeJS.svg";
import premiereCC from "./premiereCC.svg";
import puppeteer from "./puppeteer.svg";
import react from "./react.svg";
import reactRouter from "./reactRouter.svg";
import redux from "./redux.svg";
import slack from "./slack.svg";
import stripe from "./stripe.svg";
import trello from "./trello.svg";
import twilio from "./twilio.svg";
import youtube from "./youtube.svg";
import linkedIn from "./LinkedIn";
import dinnerParty from './DinnerParty';
import deathStar from './DeathStar';
import wrench from './Wrench';
import gamePad from './GamePad';
import gitHubComponent from './GitHub';
import jquery from './jquery.svg';
import materialDesign from './materialDesign.svg';

export const MaterialDesign = materialDesign;
export const JQuery = jquery;
export const GitHubComponent = gitHubComponent;
export const Wrench = wrench;
export const DeathStar = deathStar;
export const GamePad = gamePad;
export const AdobeAI = adobeAI;
export const AdobeXD = adobeXD;
export const AdobePremiere = premiereCC;
export const CSS3 = css3;
export const Firebase = firebase;
export const Flow = flow;
export const FLStudio = flStudio;
export const GitHub = github;
export const GoogleCloud = googleCloud;
export const HTML5 = html5;
export const Java = java;
export const Javascript = javascript;
export const Jest = jest;
export const LightHouse = lighthouse;
export const Mailchimp = mailchimp;
export const Mandrill = mandrill;
export const MaterialUI = materialUI;
export const NodeJS = nodeJS;
export const Puppeteer = puppeteer;
export const React = react;
export const ReactRouter = reactRouter;
export const Redux = redux;
export const Slack = slack;
export const Stripe = stripe;
export const Trello = trello;
export const Twilio = twilio;
export const YouTube = youtube;
export const LinkedIn = linkedIn;
export const DinnerParty = dinnerParty;
