import React from 'react';
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
  Toolbar,
  withStyles
} from '@material-ui/core';
import Snack from './Custom/Snack';
import Links from './Components/Links';
import { PageConsumer } from './Context';
import { withRouter } from 'react-router-dom';
import LinkButton from './Custom/LinkButton';
import { links } from './Constants';

const styles = ({
  breakpoints,
  palette,
  shadows,
  transitions: { create, duration, easing },
  zIndex
}) => ({
  header: {
    opacity: 0,
    backgroundColor: 'transparent',
    boxShadow: shadows[0],
    willChange: 'box-shadow background-color',
    transition: create(
      ['opacity', 'box-shadow', 'background-color'],
      duration.enteringScreen,
      easing.easeIn
    ),
    [breakpoints.up('md')]: {}
  },
  headerShow: {
    [breakpoints.down('sm')]: {
      backgroundColor: palette.common.white,
      boxShadow: shadows[4]
    }
  },
  icon: {
    willChange: 'color',
    transition: create('color', duration.enteringScreen, easing.easeIn)
  },
  nav: {
    zIndex: zIndex.appBar,
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0
  },
  skipLink: {
    position: 'absolute',
    top: -40,
    left: 0,
    color: 'white',
    padding: 8,
    zIndex: 100,
    '&:focus': {
      top: 0
    }
  },
  toolbar: {
    justifyContent: 'flex-end'
  }
});
const pageLinks = Object.values(links).filter(
  link => link.name !== links.intro.name
);
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  componentDidMount() {
    const appBar = document.getElementById('appBar');
    window.addEventListener('scroll', () =>
      this.onScroll(appBar, this.props.classes.headerShow)
    );
    pageLinks.map(item => {
      this[item.name] = document.getElementById(item.name);
    });
  }

  onScroll(appBar, className) {
    let scheduledAnimationFrame = false;
    if (scheduledAnimationFrame) {
      return;
    }
    scheduledAnimationFrame = true;
    requestAnimationFrame(() => {
      if (window.scrollY > 200 && !appBar.classList.contains(className)) {
        appBar.classList.add(className);
      }
      if (window.scrollY < 201 && appBar.classList.contains(className)) {
        appBar.classList.remove(className);
      }
    });
  }

  openSnack = message => {
    this.setState({ show: true, message });
  };
  closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ show: false });
  };
  handleScroll = item => {
      this[item.name].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
  };
  Links = ({ page }) => {
    const props = this.props;
    const { primary, secondary } = props.theme.palette;
    const colors = [
      primary.dark,
      primary.main,
      // primary.light,
      // secondary.light,
      secondary.main,
      secondary.dark
    ];
    return (
      <React.Fragment>
        {pageLinks.map((item, i) => (
          <LinkButton
            onClick={() => this.handleScroll(item)}
            color={colors[i]}
            key={`link_${item.url}`}
            selected={page === item.name}>
            <item.icon />
            {item.name}
          </LinkButton>
        ))}
      </React.Fragment>
    );
  };
  Nav = ({ page }) => {
    const props = this.props;
    const { primary, secondary } = props.theme.palette;
    const colors = [
      primary.dark,
      primary.main,
      // primary.light,
      // secondary.light,
      secondary.main,
      secondary.dark
    ];
    return (
      <React.Fragment>
        <BottomNavigation showLabels className={props.classes.nav}>
          {pageLinks.map((item, i) => {
            const selected = page === item.name;
            return (
              <BottomNavigationAction
                onClick={() => this.handleScroll(item)}
                key={`navAction${i}`}
                icon={
                  <item.icon
                    className={props.classes.icon}
                    style={{
                      color: selected ? colors[i] : undefined
                    }}
                  />
                }
                value={item.name}
                label={item.name}
              />
            );
          })}
        </BottomNavigation>
      </React.Fragment>
    );
  };

  render() {
    const props = this.props;
    const state = this.state;
    const isMobile = props.width === 'xs';
    return (
      <React.Fragment>
        <Snack
          onClose={this.closeSnack}
          open={state.show}
          message={state.message}
        />
        <AppBar id='appBar' className={props.classes.header}>
          <PageConsumer>
            {page => (
              <React.Fragment>
                <Toolbar
                  className={props.classes.toolbar}
                  id={'appBar'}
                  variant={'dense'}>
                  <a className={props.classes.skipLink} href={'#mainContent'}>
                    Skip to main content
                  </a>
                  <Hidden smDown>
                    <this.Links page={page} />
                  </Hidden>
                  <Links isMobile={isMobile} handleOpen={this.openSnack} />
                </Toolbar>
                <Hidden mdUp>
                  <this.Nav page={page} />
                </Hidden>
              </React.Fragment>
            )}
          </PageConsumer>
          {props.children}
        </AppBar>
      </React.Fragment>
    );
  }
}
export default withRouter(withStyles(styles, { withTheme: true })(Header));
