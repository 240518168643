import React, { Component } from 'react';
import {
  Button,
  IconButton,
  Snackbar,
  withStyles,
  withWidth
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

const styles = ({ palette }) => ({
  snackbar: {
    backgroundColor: palette.background.paper,
    color: palette.text.primary
  },
  icon: {
    color: palette.action.active
  }
});
class Snack extends Component {
  render() {
    const props = this.props;
    const isSmall = props.width === 'sm' || props.width === 'xs';

    return (
      <Snackbar
        anchorOrigin={
          props.bottom
            ? {
                vertical: 'bottom',
                horizontal: 'center'
              }
            : {
                vertical: !isSmall ? 'top' : 'bottom',
                horizontal: !isSmall ? 'right' : 'left'
              }
        }
        open={props.open}
        // autoHideDuration={6000}
        onClose={props.onClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: { root: props.classes.snackbar }
        }}
        message={props.message}
        action={
          <React.Fragment>
            {Boolean(props.buttonLabel) && (
              <Button color={'primary'} onClick={props.onClick}>
                {props.buttonLabel}
              </Button>
            )}
            <IconButton
              className={props.classes.icon}
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={props.onClose}>
              <Close />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }
}

export default withStyles(styles)(withWidth()(Snack));
