import React, { Component } from 'react';
import { Router } from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
import createBrowserHistory from 'history/createBrowserHistory';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { lightBlue, pink } from '@material-ui/core/colors';
import { CssBaseline, withWidth } from '@material-ui/core';
// import Snack from './Custom/Snack';
import Body from './Body';
// import { database } from './firebase';
// import { prod } from './Constants';
// import Freelance from './Components/Freelance';
// import Img from './Custom/ImgCR';
import './App.css';
// import Background from './Background';
import Observer from './Observer';
// import Scene from './Scene';
import Header from './Header';

const history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary: lightBlue,
    secondary: pink
    // type: 'dark'
  },
  typography: {
    fontFamily: 'Open Sans, Helvetica, Arial, sans-serif',
    useNextVariants: true
  },
  overrides: {}
});

class App extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   updateAvailable: false,
    //   reg: null
    // };
  }
  componentDidMount() {
    window.addEventListener('DOMContentLoaded', () => window.scrollTo(0, 0), {
      once: true
    });
    // serviceWorker.register({
    //   onSuccess: () => console.log('Service worker registered!'),
    //   onUpdate: reg => {
    //     console.log('Update available');
    //     this.setState({ updateAvailable: true, reg });
    //   }
    // });
    // if (prod) {
    //   this.addVisitor();
    // }
  }

  // updateToast() {
  //   const { state } = this;
  //   const handleClose = () => this.setState({ updateAvailable: false });
  //   return (
  //     <Snack
  //       bottom
  //       open={state.updateAvailable}
  //       buttonLabel={'Refresh'}
  //       message={'Update Available!'}
  //       onClick={() => {
  //         if (state.reg.waiting) {

  //           state.reg.waiting.postMessage('skipWaiting');
  //         }
  //       }}
  //       onClose={handleClose}
  //     />
  //   );
  // }
  // detectWebGL = () => {
  //   const canvas = document.createElement('canvas');
  //   const gl =
  //     canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
  //   if (gl && gl instanceof WebGLRenderingContext) {
  //     console.log('Your browser supports WebGL');
  //     return true;
  //   } else {
  //     console.log('Your browser does not support WebGL');
  //     return false;
  //   }
  // };

  // addVisitor = async () => {
  //   const data = {
  //     device: window.navigator.platform,
  //     date: new Date(Date.now()).toDateString()
  //   };
  //   await database()
  //     .ref('visits')
  //     .push(data);
  // };

  render() {
    // const state = this.state;
    const props = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <Router history={history}>
            <div className='App'>
              <Observer>
                <React.Fragment>
                  <Header width={props.width} />
                  <Body width={props.width} />
                </React.Fragment>
              </Observer>
              {/* {this.updateToast()} */}
            </div>
          </Router>
        </MuiThemeProvider>
      </React.Fragment>
    );
  }
}

export default withWidth()(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register({
//   onSuccess: () => console.log('Service worker registered!'),
//   onUpdate: () => console.log('Content has updated')
// });
