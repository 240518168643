import React from 'react';
import { links } from './Constants';
import { PageProvider } from './Context';

class Observer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: 'Profile'
    };
  }
  componentDidMount() {
    window.addEventListener('DOMContentLoaded', () => {
      this.observe();
    });
  }
  observeLazyImages = () => {
    
  };
  observe = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.25
    };
    this.observer = new IntersectionObserver(this.callback, options);
    return Object.values(links).map(item => {
      const element = document.getElementById(item.name);
      this.observer.observe(element);
    });
  };

  callback = (entries, observer) => {
    entries.forEach(entry => {
      const {
        target: { id },
        isIntersecting
      } = entry;
      // console.log(id, isIntersecting);
      requestAnimationFrame(() => {
        if (id === links[id.toLowerCase()].name && isIntersecting) {
          this.setState({ selected: links[id.toLowerCase()].name });
        }
      });
    });
  };

  render() {
    return (
      <PageProvider value={this.state.selected}>
        {this.props.children}
      </PageProvider>
    );
  }
}
export default Observer;
